import {SPECS as SdkSpecs} from '@wix/wixstores-client-storefront-sdk/dist/es/src/constants';

export const SPECS = {
  ...SdkSpecs,
  SUBSCRIPTION_PLAN_USE_TPA_RADIO_BUTTON: 'specs.stores.SubscriptionPlanUseTpaRadioButton',
  USE_LIGHTBOXES: 'specs.stores.UseLightboxes',
  USE_DROPDOWN_TPA_LABEL: 'specs.stores.UseDropdownTpaLabel',
  CART_TOOLTIP_WITHOUT_NUMBER: 'specs.stores.CartTooltipWithoutNumber',
  EDITOR_OOI: 'specs.stores.EditorOOI',
  WOW_IMAGE_IN_PRODUCT_PAGE: 'specs.stores.WowImageInProductPage',
  WOW_IMAGE_IN_PRODUCT_PAGE_MOBILE: 'specs.stores.WowImageInProductPageMobile',
  ACTIVE_DISABLED_VARIANTS: 'specs.stores.ActiveDisabledVariants',
  MULTI_CURRENCY_POC: 'specs.stores.MultiCurrencyPOC',
  DISABLE_MOBILE_NATIVE_DROPDOWN: 'specs.stores.DisableMobileNativeDropdown',
  FIX_CASHIER_PROPS_PRICE_SHIFTING: 'specs.stores.ProductPageFixCashierPropsPriceShifting',
  MOVE_CART_BI_EVENTS_TO_130: 'specs.stores.MoveCartBIEventsTo130',
  UseNewCheckoutInFastFlow: 'specs.stores.UseNewCheckoutInFastFlow',
  SHOW_AUTOMATIC_DISCOUNT_DATA_ON_PRODUCT_PAGE: 'specs.stores.ShowAutomaticDiscountDataOnProductPage',
  RouterBreadcrumbsInProductPage: 'specs.stores.RouterBreadcrumbsInProductPage',
  ShouldRoundItemsTotalWithAdditionalFees: 'specs.stores.ShouldRoundItemsTotalWithAdditionalFees',
} as const;
